import React from "react"
import EventLayout from "../../components/layouts/eventLayout"
import flyer from "../../images/2024-Chamber-Banquet-Flyer.jpg"

const ChamberBanquetIndexPage = props => (
  <EventLayout pageTitle="Chamber Banquet">
    <h2>Thursday, February 27, 2025 - Information Coming Soon</h2>
{/*--    <p css={{ textAlign: `center`}}>
       <img css={{ width: `90%`, padding: `0 0` }} src={flyer} />
    </p>
        <div css={{margin: `2rem 0`}}>
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
      }} href="/docs/2024SalutetoBusinessNominationForm.png">2024 Salute to Business Nomination Form</a>
      </div>
    -- */}
  </EventLayout>
)

export default ChamberBanquetIndexPage
